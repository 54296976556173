.ticketTable tbody {
  font-size: 1rem;
  color: #494949;
}

td {
  /*padding: 1rem 0;*/

  border-bottom: 1px solid #ddd;
}

.add,
.remove {
  display: inline-block;
  background-color: #32898f;
  text-decoration: none;
  margin-left: 1rem;
  padding: 0.2rem 0.6rem;
  color: #fff;
  text-align: center;
  border-radius: 0.2rem;
  border: none;
  font-weight: 700;
}

.add:hover {
  background: #28767b;
}

.remove {
  margin-left: 0;
  margin-right: 1rem;
}

.remove:hover {
  background: #28767b;
}

.addDisabled,
.addDisabled:hover {
  display: inline-block;
  text-decoration: none;
  margin-left: 1rem;
  padding: 0.2rem 0.6rem;
  text-align: center;
  border-radius: 0.2rem;
  font-weight: 700;
  background-color: #ccc;
  cursor: default;
  color: #ffffff;
  border: none;
}

.removeDisabled,
.removeDisabled:hover {
  display: inline-block;
  text-decoration: none;
  margin-right: 1rem;
  padding: 0.2rem 0.6rem;
  text-align: center;
  border-radius: 0.2rem;
  font-weight: 700;
  background-color: #ccc;
  cursor: default;
  color: #ffffff;
  border: none;
}

.total {
  margin-right: 5px;
}

.fees {
  font-size: 0.7em;
  color: #999;
  margin-left: 0.3rem;
}

.descriptionColumn {
  width: 60%;
  padding: 1rem 1rem 1rem 0;
  vertical-align: top;
  font-size: 1.1em;
}

.descriptionDetail {
  font-size: 0.8em;
  display: block;
}

.pricesColumn {
  width: 20%;
  padding: 1rem 0;
  vertical-align: top;
  font-size: 1.1em;
}

.controlsColumn {
  text-align: right;
  width: 20%;
  padding: 1rem 0;
  vertical-align: top;
  font-size: 1.1em;
}

.remainingMobile {
  clear: both;
  color: red;
  font-size: 0.7em;
  display: block;
  margin-right: 7%;
  margin-top: 0.5em;
}

.controlsColumn span.soldOut {
  margin-right: 7%;
}

.description {
}

.descriptionTooltipMobile {
  display: none;
}

@media only screen and (min-width: 530px) and (max-width: 767px) {
  .descriptionColumn {
    width: 60%;
  }
  .remainingMobile {
    margin-right: 13%;
  }
  .controlsColumn {
    width: 20%;
  }
  .pricesColumn {
    width: 20%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 529px) {
  .add,
  .addDisabled,
  .addDisabled:hover {
    margin-left: 1rem;
  }
  .remove,
  .removeDisabled,
  .removeDisabled:hover {
    margin-right: 1rem;
  }
  .descriptionColumn {
    width: 50%;
  }
  .controlsColumn {
    width: 25%;
  }
  .pricesColumn {
    width: 25%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .remainingMobile {
    display: block;
    margin-right: 13%;
  }
  .co .descriptionTooltip {
    display: none;
  }
  .descriptionTooltipMobile {
    display: block;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}

@media only screen and (max-width: 399px) {
  .add,
  .addDisabled,
  .addDisabled:hover {
    margin-left: 0.5rem;
  }
  .remove,
  .removeDisabled,
  .removeDisabled:hover {
    margin-right: 0.5rem;
  }
  .descriptionColumn {
    width: 45%;
  }
  .pricesColumn {
    width: 21%;
  }
  .controlsColumn {
    width: 34%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .remainingMobile {
    margin-right: 13%;
  }
  .descriptionTooltip {
    display: none;
  }
  .descriptionTooltipMobile {
    display: block;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}
