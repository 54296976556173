
.root {

}

.title, .titleActive {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #999;
  margin: 0;
  font-weight: 700;
  margin-right: 1.5em;
  padding-bottom: 0.5rem;
}
.title:hover, .titleActive:hover {
  border-bottom: 2px solid #666;
}
.titleActive {
  border-bottom: 2px solid #666;
  color: #666;
}

.login {
  color: red;
}

.noTickets {
  min-height: 9em;
  margin: 5rem 0;
}


@media only screen and (min-width: 310px) and (max-width: 479px) {
	.title {
  	font-size: 0.8em;
  	margin-right: 1em;
	}
  .titleActive {
    font-size: 0.8em;
  }
  .noTickets {
    margin: 5rem 0;
  }
}

@media only screen and (max-width: 309px) {
  .title {
    font-size: 0.8em;
    width: 100%;
    display: block;
    margin: 0.5em 0;
  }
  .titleActive {
    font-size: 0.8em;
    width: 100%;
    display: block;
    margin: 0.5em 0;
    border-bottom: none;
  }
  .title:hover, .titleActive:hover {
    border-bottom: none;
  }
  .noTickets {
    margin: 5rem 0;
  }
}
