.registerForm {
	margin: 0.5rem 0 0 0;
}

.lastName {
  width: 48%;
  float: left;
}
.firstName {
  width: 48%;
  float: left;
  padding-right: 4%;
}
.checkbox div {
	display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 1rem;
}

.checkbox label {
	flex: 1;
	margin-left: 1.5rem;
}

.checkbox input {
	position: absolute;
	left: 0;
  top: 0.15rem;
  width: auto;
}

.reviewButton {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 100%;
  float: left;
  clear: both;
  text-align: center;
}

.reviewButton:hover {
	background-color: #28767b;
}

.reviewButtonDisabled {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 25%;
  float: left;
  clear: both;
  text-align: center;
  background-color: #ccc;
}
.formError{
  color: #f3545b;
  margin: -0.5rem 0 0.75rem 0;
  font-size: 0.9rem;
}

.reviewButtonDisabled:hover {
  background-color: #ccc;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.reviewButton {
		width: 100%;
	}
	.reviewButtonDisabled {
		width: 100%;
	}
}

@media only screen and (max-width: 479px) {
	.checkbox input {
    	top: 0.05rem;
	}
	.reviewButton {
		width: 100%;
	}
	.reviewButtonDisabled {
		width: 100%;
	}
	.lastName {
	  width: 100%;
	  float: left;
	}
	.firstName {
	  width: 100%;
	  float: left;
	  padding-right: 0;
	}
}
