.root {
	width: 50%;
	margin: auto;
}
.discountForm {
	width: 100%;
	margin: 0;
}

.inputContainer {
	width: 100%;
	display: block;
	vertical-align: top;
}

.inputContainer input {
	width: 100%;
	padding: 0.5em;
	margin: 0;
	margin-bottom: 0.5rem;
}

.discountAppyButtonContainer {
	width: 100%;
	margin:0;
}

.discountApplyButton {
  background-color: #32898f;
  padding: 0.725rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.discountApplyButton:hover {
	background-color: #28767b;
}

.discountApplyButtonDisabled {
  padding: 0.725rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  background-color: #ccc;
  cursor: default;
}

.discountApplyButtonDisabled:hover {
  background-color: #ccc;
}

.formError {
	color: #f3545b;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
	.discountApplyButton, .discountApplyButtonDisabled {
		padding: 0.65rem 0;
	}
	.root {
		width: 70%;
	}
}

@media only screen and (max-width: 309px) {
  .discountApplyButton, .discountApplyButtonDisabled {
    padding: 0.675rem 0;
  }
	.root {
		width: 80%;
	}
}
