
.container {
  margin: 2rem 0 3rem 0;
  min-height: 9em;
}

.ticketTable {
  width: 100%;
  border-collapse: collapse;
}

.noTickets {
  margin: 5rem 0;
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
	.container {
 		margin: 1rem 0 1rem 0;
    min-height: 4em;
	}
  .noTickets {
    margin: 5rem 0;
  }
}

@media only screen and (max-width: 309px) {
  .container {
    margin: 1rem 0 1rem 0;
    min-height: 4em;
  }
  .noTickets {
    margin: 5rem 0;
  }
}
