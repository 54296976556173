
.root {
}

.row {
}

.panel h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.eventSummary {
  margin-bottom: 1rem;
}
.purchaseItem {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.purchaseItem:last-child {
  border-bottom: 0;
}

.purchaseItem p {
  margin: 0.2rem 0;
  font-size: 0.9em;
}

.purchaseInfo {
  width: 80%;
  display: inline-block;
}

.purchaseSeats:before {
  content:', ';
}

.purchaseSeats:first-child:before {
  content:'';
}

.purchasePrice {
  float: right;
  display: inline-block;
  width: 20%;
  text-align: right;
}

.totals {
}

.subTotal {
}

.subTotal p {
  margin: 0.2rem 0;
  display: inline-block;
}

.subTotal p.subTotalFigure {
  display: inline-block;
  float: right;
}

.total {
  margin-top: 0;
  padding-top: 1.5rem;
  clear: both;
}

.totalLabel {
  display: inline-block;
  font-weight: 700;
  font-size: 1.1em;
}

.totalValue {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 700;
  float: right;
}

.totalFooter {
  font-size: 0.7em;
  margin-top: 0;
}

.orderInfoPanel {
  width: 48%;
  float: left;

}

.orderBreakdownPanel {
  width: 46%;
  float: right;
  border-left: 1px solid #ccc;
  padding-left: 3%;
}

.orderBreakdownPanel h3 {
  display: inline-block;
}

.downloadTicketsContainer {
  margin: 1rem 0;
  width: 100%;
}

.downloadTicketsContainer a {
  width: 100%;
  display: block;
}

.downloadTicketsButton {
  background-color: #32898f;
  padding: 0.5rem 0;
  width: 100%;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
}

.downloadTicketsButton:hover {
  background-color: #28767b;
}

.successGuideText {
  margin-top: 2rem;
}

.shareButtons {
	margin: 0 0 2rem 0;
}

a.shareButtonFb, a.shareButtonTwitter {
	background-color: #3B5998;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-weight: 700;
  font-size: 0.9em;
  text-decoration: none;
  border-radius: 2px;
  clear: both;
  text-align: center;
  display: inline-block;
}

a.shareButtonTwitter {
	background-color: #4099FF;
	margin-left: 1rem;
	display: inline-block;
}

.shareButtonLabel {
  margin-left: 0.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  a.shareButtonFb, a.shareButtonTwitter {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .orderInfoPanel {
    width: 100%;
    float: none;
  }
  .orderBreakdownPanel {
    width: 100%;
    float: none;
    margin-top: 0;
    border-left: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .orderInfoPanel {
    width: 100%;
    float: none;
  }
  .orderBreakdownPanel {
    width: 100%;
    float: none;
    margin-top: 0;
    border-left: 0;
    padding-left: 0;
  }
  a.shareButtonFb, a.shareButtonTwitter {
    padding: 0.75rem 0.6rem;
  }
  a.shareButtonTwitter {
    margin-left: 0.75rem;
  }
  .downloadTicketsContainer {
    margin-top: 0.8rem;
    font-size: 0.9em;
  }
  .downloadTicketsButton {
    padding: 0.5rem 0;
  }
}

@media only screen and (max-width: 319px) {
  .orderInfoPanel {
    width: 100%;
    float: none;
  }
  .orderBreakdownPanel {
    width: 100%;
    float: none;
    margin-top: 0;
    border-left: 0;
    padding-left: 0;
  }
  a.shareButtonFb, a.shareButtonTwitter {
    padding: 0.75rem 0;
    width: 100%;
    display: block;
  }
  a.shareButtonTwitter {
    margin-left: 0;
    margin-top: 0.5rem;
  }
  .downloadTicketsContainer {
    margin-top: 0.8rem;
    font-size: 0.9em;
  }
  .downloadTicketsButton {
    padding: 0.5rem 0;
  }
}
