.registerHeader {
  padding: 0.5rem 0 0 0;
}

.registerHeader h2 {
  margin-top: 0.2rem;
  display: inline-block;
}

h2 {
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.loginBox {
  float: right;
}

.loginBox p {
  display: inline-block;
  margin-top: 0.3rem;
}

.loginButton {
  background-color: #32898f;
  margin-left: 0.7rem;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  float: right;
}

.loginButton:hover {
  background-color: #28767b;
}

.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1rem
}

.backButton a:hover {
  color: #666;
}

span.backButton {
  margin-right: 0.3rem;
}

.row {
  width: 100%;
  padding-bottom: 1rem;
}

.leftPanel {
	width: 46%;
  float: left;
  border-right: 1px solid #ccc;
  padding-right: 3%;
}

.rightPanel {
  width: 48%;
  float: right;
  margin-top: 3.3rem;
}

.paymentHeader h3 {
  margin-top: 0;
  margin-bottom: 2rem;
  display: inline-block;
}

.paymentHeader img {
  width: 200px;
  height: 28.5px;
  float: right;
}

.confirmAndPayButton  {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
}

.confirmAndPayButton:hover {
	background-color: #28767b;
}

.disabledButton {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
  background-color: #ccc;
  cursor: default;
}

.disabledButton:hover {
  background-color: #ccc;
}

.confirmAndPayButton, .disabledButton {
    margin-bottom: 0.5rem;
  }

.paymentFooter p {
  font-size: 0.8em;
  color: #ccc;
}

.basketSummary {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .loginBox p {
    margin-top: 0.5rem;
    font-size: 0.9em;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
  .leftPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .rightPanel {
    width: 100%;
    float: none;
    margin-top: 0;
  }
  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }
  .basketSummary {
    margin-bottom: 1rem;
  }
  .loginBox p {
    margin-top: 0.5rem;
    font-size: 0.9em;
  }
  .loginButton {
    padding: 0.5rem;
  }
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.45rem;
  }
  .leftPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .paymentHeader h3 {
    margin-bottom: 1rem;
  }
  .rightPanel {
    width: 100%;
    float: none;
    margin-top: 0;
  }
  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }
  .basketSummary {
    margin-bottom: 1rem;
  }
  .paymentHeader img {
    width: 140px;
    float: right;
    height: 20px;
  }
}

@media only screen and (max-width: 309px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.45rem;
  }
  .leftPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .paymentHeader h3 {
    margin-bottom: 1rem;
  }
  .rightPanel {
    width: 100%;
    float: none;
    margin-top: 0;
  }
  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }
  .basketSummary {
    margin-bottom: 1rem;
  }
  .paymentHeader img {
    width: 110px;
    float: right;
    height: 16px;
  }
  .loginBox {
    float: left;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
