/* -- Global styles */

:global body {
  font-family: 'lato', sans serif;
  color: #666;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global body a {
  text-decoration: none;
  color: inherit;
}

:global h1 {
  font-size: 2em;
  font-weight: 700;
}

:global h2 {
  font-size: 1.2em;
  font-weight: 700;
}

:global h5 {
  text-transform: uppercase;
  color: #666;
  font-weight: 400;
  font-size: 0.9em;
}

:global input,
:global textarea {
  font-family: 'lato', sans serif;
  font-size: 1.1em;
  padding: 0.5rem;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  background-clip: padding-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 1rem 0;
}

:global input:focus,
:global textarea:focus {
  outline: 0;
  border-color: #999;
}

:global button {
  font-family: 'lato', sans serif;
}

:global input:-webkit-autofill,
:global select:-webkit-autofill,
:global textarea:-webkit-autofill {
  -webkit-text-fill-color: #32898f;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out 1s;
}

:global textarea {
  resize: none;
}

:global .StripeElement {
  margin: 1rem 0;
  padding: 0.5rem;
  border-image: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ddd;
    border-radius: 3px;
    box-shadow: none;
    background-clip: padding-box;
    box-sizing: border-box;
}

:global .StripeElement--invalid {
  border-color: #f3545b;
}

:global .StripeElement--focus {
  outline: 0;
  border-color: #999;
}


/* -- Styles for the App container */

.root {
  padding: 2% 2% 2.5% 2%;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

@media only screen and (max-width: 479px) {
  :global body {
    font-size: 0.9em;
  }
  .root {
    padding: 3% 3% 3.5% 3%;
  }
  .header {
    right: 3%;
  }
}

/* Transition group style for modal popup
 *
 * see: https://facebook.github.io/react/docs/animation.html
 *
 * */

.modalTransition {
}

.modalTransition-appear {
  opacity: 0.01;
}

.modalTransition-appear.modalTransition-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.modalTransition-enter {
  opacity: 0.01;
}

.modalTransition-enter.modalTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.modalTransition-leave {
  opacity: 1;
}

.modalTransition-leave.modalTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
